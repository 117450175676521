<template>
  <v-dialog
    persistent
    content-class="profile-dialog"
    width="618"
    :value="show"
    @click:outside="handleClickOutside"
    @keydown="handleKeydown"
  >
    <v-card :loading="form.submitting">
      <v-form v-model="form.valid" @submit.prevent="save">
        <v-card-title>Profil</v-card-title>

        <v-card-text>
          <v-row align="center">
            <v-col cols="12" sm="4">
              <v-avatar class="mb-5" size="100">
                <v-img :src="imageUrl"></v-img>
              </v-avatar>
            </v-col>
            <v-col cols="12" sm="8">
              <v-file-input
                label="Bild hochladen"
                clear-icon="$delete"
                prepend-icon="$upload"
                show-size
                truncate-length="20"
                v-model="previewImage"
                @input="user.deleteAvatar = false"
              ></v-file-input>

              <temeno-button
                action
                small
                class="ml-8"
                @click="handleClickDeleteAvatarButton"
              >
                <v-icon left>$delete</v-icon>
                Bild entfernen
              </temeno-button>
            </v-col>
          </v-row>

          <v-text-field
            class="validation"
            autocomplete="off"
            v-model="user.showName"
            :rules="form.rules.showName"
            label="Anzeigename"
            clear-icon="$delete"
            clearable
            required
          ></v-text-field>

          <!--
          <v-text-field
            class="validation"
            autocomplete="off"
            v-model="user.email"
            :rules="form.rules.email"
            label="E-Mail-Adresse"
            clear-icon="$delete"
            clearable
          ></v-text-field>
          -->

          <update-password-button action class="my-2"></update-password-button>

          <h3 class="mt-6 mb-1">
            Meine Verfügbarkeit
            <help-tooltip action max-width="350">
              Andere Teilnehmer erhalten einen Hinweis, wenn sie Sie außerhalb
              Ihrer verfügbaren Zeiten einladen.
            </help-tooltip>
          </h3>

          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th title="Montag">Mo</th>
                  <th title="Dienstag">Di</th>
                  <th title="Mittwoch">Mi</th>
                  <th title="Donnerstag">Do</th>
                  <th title="Freitag">Fr</th>
                  <th title="Samstag">Sa</th>
                  <th title="Sonntag">So</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <v-checkbox v-model="user.workingTimes.monday"></v-checkbox>
                  </td>
                  <td>
                    <v-checkbox
                      v-model="user.workingTimes.tuesday"
                    ></v-checkbox>
                  </td>
                  <td>
                    <v-checkbox
                      v-model="user.workingTimes.wednesday"
                    ></v-checkbox>
                  </td>
                  <td>
                    <v-checkbox
                      v-model="user.workingTimes.thursday"
                    ></v-checkbox>
                  </td>
                  <td>
                    <v-checkbox v-model="user.workingTimes.friday"></v-checkbox>
                  </td>
                  <td>
                    <v-checkbox
                      v-model="user.workingTimes.saturday"
                    ></v-checkbox>
                  </td>
                  <td>
                    <v-checkbox v-model="user.workingTimes.sunday"></v-checkbox>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <v-row>
            <v-col>
              <time-input
                label="von"
                v-model="user.workingTimes.from"
              ></time-input>
            </v-col>
            <v-col>
              <time-input
                label="bis"
                v-model="user.workingTimes.to"
              ></time-input>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <temeno-circle-button
            action
            title="abbrechen"
            @click="handleClickCancelButton"
          >
            $close
          </temeno-circle-button>

          <temeno-circle-button
            action
            title="speichern"
            type="submit"
            :disabled="!form.valid"
            :loading="form.submitting"
          >
            {{ !form.valid ? '$approve_action_disabled' : '$approve' }}
          </temeno-circle-button>
        </v-card-actions>

        <v-expand-transition>
          <div v-show="showUnsavedChangesWarning">
            <v-divider></v-divider>

            <v-card-text>
              <strong>Änderungen verwerfen?</strong>
              Alle Änderungen gehen unwiderruflich verloren.
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <temeno-circle-button
                action
                title="weiter bearbeiten"
                @click="handleClickContinueEditingButton"
              >
                $arrow_left
              </temeno-circle-button>

              <temeno-button action @click="handleClickForceCancelButton">
                Änderungen verwerfen
              </temeno-button>
            </v-card-actions>
          </div>
        </v-expand-transition>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import _cloneDeep from "lodash/cloneDeep";
import _isEqual from "lodash/isEqual";
import api from "@/api";
import { isEmail } from "@/functions";

export default {
  name: "ProfileDialog",
  components: {
    HelpTooltip: () => import("@/components/HelpTooltip"),
    TemenoButton: () => import("@/components/TemenoButton"),
    TemenoCircleButton: () => import("@/components/TemenoCircleButton"),
    TimeInput: () => import("@/components/TimeInput"),
    UpdatePasswordButton: () => import("@/components/UpdatePasswordButton"),
  },
  props: {
    show: {
      type: Boolean,
    },
  },
  data() {
    return {
      form: {
        rules: {
          showName: [
            (v) => (v && v.length > 0) || this.$t("validation.mandatory"),
          ],
          email: [(v) => isEmail(v) || this.$t("validation.email")],
        },
        valid: false,
        submitting: false,
      },
      previewImage: null,
      showUnsavedChangesWarning: false,
      user: {
        deleteAvatar: false,
        workingTimes: {},
      },
    };
  },
  computed: {
    encodedUserShowName() {
      let result = this.user.showName;
      result = result.replaceAll(/\s+/g, " ");
      result = encodeURIComponent(result);
      result = result.replaceAll("%20", "+");
      return result;
    },
    imageUrl() {
      if (this.previewImageUrl != null) {
        return this.previewImageUrl;
      } else if (this.user.deleteAvatar) {
        return this.svgImageUrl;
      } else {
        return (
          this.$store.state.user.avatar.url +
          "?t=" +
          this.$store.state.user.avatarLastChanged
        );
      }
    },
    previewImageUrl() {
      return this.previewImage ? URL.createObjectURL(this.previewImage) : null;
    },
    svgImageUrl() {
      if (!this.encodedUserShowName) {
        return null;
      }
      const baseUrl =
        this.$store.getters["connection/serverBaseUrl"] +
        "/api/avatars/initials/";
      return baseUrl + this.encodedUserShowName + ".svg";
    },
    unsavedChanges() {
      if (this.previewImage) {
        // user has uploaded a new avatar image
        return true;
      }
      return !_isEqual(this.user, this.copySavedState());
    },
  },
  watch: {
    show(value) {
      if (value) {
        this.previewImage = null;
        this.showUnsavedChangesWarning = false;
        // copy current state into local data model
        this.user = this.copySavedState();
      }
    },
  },
  methods: {
    cancel(force = false) {
      if (!force && this.unsavedChanges) {
        this.showUnsavedChangesWarning = true;
        const el = document.getElementsByClassName("profile-dialog")[0];
        setTimeout(() => {
          el.scrollTo({ top: el.scrollHeight, behavior: "smooth" });
        }, 300);
      } else {
        this.$emit("cancel");
      }
    },
    copySavedState() {
      const { showName, email, workingTimes } = this.$store.state.user;
      return {
        ..._cloneDeep({ showName, email, workingTimes }),
        deleteAvatar: false,
      };
    },
    handleClickCancelButton() {
      this.cancel();
    },
    handleClickCloseButton() {
      this.cancel();
    },
    handleClickContinueEditingButton() {
      this.showUnsavedChangesWarning = false;
    },
    handleClickDeleteAvatarButton() {
      this.user.deleteAvatar = true;
      this.previewImage = null;
    },
    handleClickForceCancelButton() {
      this.cancel(true);
    },
    handleClickOutside() {
      if (this.showUnsavedChangesWarning && this.unsavedChanges) {
        return;
      }
      this.cancel();
    },
    handleKeydown(key) {
      if (this.showUnsavedChangesWarning && this.unsavedChanges) {
        return;
      } else if (key.code === "Escape") {
        this.cancel();
      }
    },
    save() {
      this.form.submitting = true;
      api
        .updateUsersMe(this.user, this.previewImage)
        .then(() => {
          this.$store.commit("user/setWorkingTimes", this.user.workingTimes);
          if (this.previewImage || this.user.deleteAvatar) {
            this.$store.commit("user/setAvatarChanged");
          }
          api
            .refreshToken()
            .then((response) => {
              const { data } = response;
              this.$store.commit("user/setAccessToken", data.access_token);
              this.$store.commit("user/setRefreshToken", data.refresh_token);
              this.$emit("save");
              this.form.submitting = false;
            })
            .catch((e) => {
              this.form.submitting = false;
              console.error(e);
            });
        })
        .catch((e) => {
          this.form.submitting = false;
          console.error(e);
        });
    },
  },
  i18n: {
    messages: {
      de: {
        validation: {
          email: "Bitte geben Sie eine gültige E-Mail-Adresse an",
          mandatory: "Dies ist ein Pflichtfeld",
        },
      },
    },
  },
};
</script>
